import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography, Paper, Grid, Container } from '@mui/material';
import axios from "axios";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { MdKeyboardArrowRight } from "react-icons/md";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../assets/logo.png";
import Link from '@mui/material/Link';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { AiOutlineClose } from "react-icons/ai";
import Video from './Video';
const drawerWidth = 240;
const navItems = [
  { path: "Features", route: "/" },
  { path: "Enterprise", route: "/" },
  { path: "Support", route: "/" },
];

const Dashboard = () => {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const closeNav = () => {
    setMobileOpen(false);
  };

  // const [isLoading, setIsLoading] = useState(true);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [fetchedUserData, setFetchedUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({}); 
  //const [paymentLink, setPaymentLink] = useState('');
  //const [starterPaymentLink, setStarterPaymentLink] = useState('');
  //const [businessPaymentLink, setBusinessPaymentLink] = useState('');
  //const [teamPaymentLink, setTeamPaymentLink] = useState('');

  useEffect(() => {
    const checkAuthSession = async () => {
      // setIsLoading(true);
      if (isAuthenticated && user) {
        setFetchedUserData(null); // Reset the state for fetched user data
        //setPaymentLink(''); // Reset the payment link state
        //setStarterPaymentLink(''); // Reset the payment link state
        //setBusinessPaymentLink(''); // Reset the payment link state
        //setTeamPaymentLink(''); // Reset the payment link state

        try {
          await getAccessTokenSilently({ cacheMode: 'default' });
        } catch (error) {
          console.log('Error refreshing access token:', error);
          loginWithRedirect();
        }
        await saveUserDataToDynamoDB(); // wait for this to complete
        await fetchUserData(); // then fetch the user data
        console.log('Fetched user data:', fetchedUserData);
        console.log('sub creator', subscriptionData.subscriptionCreator);
        //await createStarterPaymentLink(); 
        //console.log('Starter Payment link:', starterPaymentLink);
        //await createBusinessPaymentLink(); 
        //console.log('Starter Payment link:', businessPaymentLink);
        //await createTeamPaymentLink(); 
        //console.log('Starter Payment link:', teamPaymentLink);
      }
      // setIsLoading(false);
    };

      checkAuthSession(); 
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect, user]);


  //
  //
  // need to add a loading spinner for when the user data is being fetched
  //                      not the auth0 one
  //
  //


  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const saveUserDataToDynamoDB = async () => {
    if (isAuthenticated && user) {
      // Replace this URL with your API endpoint
      const apiUrl = process.env.REACT_APP_API_GATEWAY_USERS;
      const email = user.email;
      const team_id = user.sub ? extractTeamId(user.sub) : null;
      const users = await axios.get(apiUrl);
      const checkIfUserExists = ({ users, email, team_id }) => {
        return users.some(user => {
          return user.email === email && user.team_id === team_id;
        });
      }
      
      // Check if user exists
      const userExists = checkIfUserExists({ users: users.data, email: email, team_id: team_id });
      // If user does not exist, save user data to DynamoDB
      if (!userExists) {
        try {
          await axios.put(
            apiUrl,
            { 
              email: email, 
              team_id: team_id,
            },
          );
          console.log('User data saved to DynamoDB');
        } catch (error) {
          console.log('Error saving user data to DynamoDB', error);
        }
      } else {
        console.log('User already exists in DynamoDB');
      }
    } else {
      console.log('User is not authenticated');
    }
  };

  const findUniqueUser = (users, email, teamId) => {
    return users.find(user => user.email === email && user.team_id === teamId);
  };

  // Set state with the current user 
  const fetchUserData = async () => {
    const apiUrl = process.env.REACT_APP_API_GATEWAY_USERS;
    const teamId = user.sub ? extractTeamId(user.sub) : null;
  
    try {
      // Check if the team has a subscription
      const subscriptionResponse = await axios.get(`${process.env.REACT_APP_API_GATEWAY_SUB}`, {
        params: { team_id: teamId, email: user.email }
    });
      const hasSubscription = subscriptionResponse.data.status; // Adjust based on actual response structure
      const isSubscriptionCreator = subscriptionResponse.data.isSubscriptionCreator;
      console.log('Has subscription?', hasSubscription);
      console.log('Is subscription creator?', isSubscriptionCreator);
  
      // Fetch the current user data from DynamoDB
      const usersResponse = await axios.get(apiUrl);
      const uniqueUser = findUniqueUser(usersResponse.data, user.email, teamId);
  
      // let customerStripeInfo = {};
      // let customerSubscriptionInfo = {};
      let subscription = {};
      let subscriptionCreator = {};
  
      // Fetch additional Stripe information if the team has a subscription
      if (hasSubscription) {
        subscription = subscriptionResponse.data.subscription;
        console.log('Subscription:', subscription);
        subscriptionCreator = subscriptionResponse.data.subscriptionCreator;
      }
  
      setFetchedUserData({
        ...uniqueUser,
        // ...customerStripeInfo,
        // ...customerSubscriptionInfo,
      });
  
      setSubscriptionData({
        ...subscription,
        hasSubscription,
        isSubscriptionCreator,
        subscriptionCreator
      });

    } catch (error) {
      console.log('Error:', error);
    }
  };
  
  const extractTeamId = (userSub) => {
    const parts = userSub.split('|');
    return parts[2].split('-')[0];
  };

  //const createPaymentLink = async () => {
  //  try {
  //    const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_PAYMENT_LINK}`, {
  //      params: { team_id: extractTeamId(user.sub), email: user.email }
  //    });
  //    console.log('Payment link response:', response.data);
  //    setPaymentLink(response.data); // Set the fetched URL in the state
  //  } catch (error) {
  //    console.error('Error creating payment link:', error);
  //  }
  //};

  const createFreeTrial = async () => {
   try {
        const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_FREE_TRIAL_LINK}`, {
          params: { team_id: extractTeamId(user.sub), email: user.email }
        });
        //console.log('Create trial response:', response.data);
        // Redirect to the URL in response.data
        window.location.href = response.data;
        //setFreeTrialData(response.data); // Set the fetched URL in the state
      } catch (error) {
        console.error('Error creating free trial:', error);
      }
  };

  const checkoutStarter = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_STARTER_CHECKOUT}`, {
        params: { team_id: extractTeamId(user.sub), email: user.email }
      });
      //console.log('Checkout session response:', response.data);
      window.location.href = response.data;
      //setStarterPaymentLink(response.data); // Set the fetched URL in the state
    } catch (error) {
      console.error('Error creating payment link:', error);
    }
  };

  const checkoutBusiness = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_BUSINESS_CHECKOUT}`, {
        params: { team_id: extractTeamId(user.sub), email: user.email }
      });
      //console.log('Checkout session response:', response.data);
      window.location.href = response.data;
      //setBusinessPaymentLink(response.data); // Set the fetched URL in the state
    } catch (error) {
      console.error('Error creating payment link:', error);
    }
  };

  const checkoutTeam = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_GATEWAY_TEAM_CHECKOUT}`, {
        params: { team_id: extractTeamId(user.sub), email: user.email }
      });
      //console.log('Checkout session response:', response.data);
      window.location.href = response.data;
      //setTeamPaymentLink(response.data); // Set the fetched URL in the state
    } catch (error) {
      console.error('Error creating payment link:', error);
    }
  };

  const convertUnixToDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
    const formattedDate = date.toLocaleDateString("en-US", {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    return formattedDate;
  };

  const defaultTheme = createTheme();


  const DashboardPricingCard = (props) => {
    return (
      <div className="pricing-card">
        <Typography
          component="h4"
          variant="h4"
          align="left"
          sx={{ color: "#26425a", fontWeight: "600" }}
          gutterBottom
        >
          {props.planName}
        </Typography>
        {props.price && (
          <Typography
            component="h4"
            variant="h4"
            align="left"
            sx={{ color: "#576b73", fontWeight: "300" }}
            gutterBottom
          >
            ${props.price}/<span className="pricing-card-month">mon</span>
          </Typography>
        )}
        <ul>
          <li>
            <MdKeyboardArrowRight />
            {props.features.feature1}
          </li>
          <li>
            <MdKeyboardArrowRight />

            {props.features.feature2}
          </li>
          <li>
            <MdKeyboardArrowRight />

            {props.features.feature3}
          </li>
          <li>
            <MdKeyboardArrowRight />

            {props.features.feature4}
          </li>
        </ul>
        {
          props.emailLink ? (
            <a href={`mailto:${props.emailLink}`} className="nav-btn">
              {props.btnText}
            </a>
          ) : props.planName === "freeTrial" ? (
            <button className="nav-btn" onClick={createFreeTrial}>
                Start Free Trial
            </button>
          ) : props.planName === "Starter" ? (
            <button className="nav-btn" onClick={checkoutStarter}>
                Add To Slack
            </button>
          ) : props.planName === "Business" ? (
            <button className="nav-btn" onClick={checkoutBusiness}>
                Add To Slack
            </button>
          ) : props.planName === "Teams" ? (
            <button className="nav-btn" onClick={checkoutTeam}>
                Add To Slack
            </button>
          ) : (
            <button className="nav-btn">{props.btnText}</button>
          )
        }
      </div>
    );
  };

  const DashboardPricingCardSales = (props) => {
    return (
      <div className="pricing-card dont-align-center">
        <Typography
          component="h4"
          variant="h4"
          align="left"
          sx={{ color: "#26425a", fontWeight: "600" }}
          gutterBottom
        >
          {props.planName}
        </Typography>
        {props.price && (
          <Typography
            component="h4"
            variant="h4"
            align="left"
            sx={{ color: "#576b73", fontWeight: "300" }}
            gutterBottom
          >
            ${props.price}/<span className="pricing-card-month">mon</span>
          </Typography>
        )}
        <ul className="dont-align-center">
          <li className="dont-align-center">
            <MdKeyboardArrowRight />
            {props.features.feature1}
          </li>
          <li>
            <MdKeyboardArrowRight />

            {props.features.feature2}
          </li>
          <li>
            <MdKeyboardArrowRight />

            {props.features.feature3}
          </li>
          <li>
            <MdKeyboardArrowRight />

            {props.features.feature4}
          </li>
        </ul>
        <a href={`mailto:${props.emailLink}`} className="nav-btn">
          {props.btnText}
        </a>
      </div>
    );
  };

  //const container =
  //  window !== undefined ? () => window().document.body : undefined;
  //

  const drawer = (
    <Box
      // onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        position: "relative",
      }}
    >
      <IconButton
        color="inherit"
        aria-label="close drawer"
        onClick={closeNav}
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <AiOutlineClose />
      </IconButton>
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText
                // primary={item.path}
                sx={{ textTransform: "capitalize" }}
              >
                <Link style={{ fontWeight: "500" }} to={item.route}>
                  {item.path}
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}

        <Button 
          href="#" 
          variant="outlined" 
          sx={{ my: 1, mx: 1.5 }} 
          onClick={(e) => {
            e.preventDefault(); // Prevent default action
            logout({ returnTo: window.location.origin });
          }}>
          Logout
        </Button>
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />

      
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          position="static"
          elevation={0}
          sx={{
            backgroundColor: "#fff",
            color: "#000",
            maxWidth: "1600px",
            margin: "auto",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="h2"
              component="div"
              sx={{
                flexGrow: 1,

                color: "#26425a",
              }}
            >
              <Link
                to={"/"}
                className="nav-logo-main"
                style={{ display: "flex", alignItems: "center", gap: "1px" }}
              >
                <img src={Logo} alt="logo" className="nav-logo" />
                <h3 className="nav-logo-text">Insight Bot</h3>
              </Link>
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end" // Move the hamburger icon to the right
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>

            <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              Logout
            </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            anchor="right"
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
      <Container>
        <Paper
          style={{
            padding: "20px",
            marginTop: "20px",
            border: "3px solid #576b73",
            borderRadius: "25px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography 
                variant="h6"
                sx={{ fontWeight: 600, color: "#052843" }}
              >
                User Dashboard
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography 
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#052843" }}
              >
                User: {user.email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography 
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#052843" }}
              >
                Team ID: {user.sub ? extractTeamId(user.sub) : null}
              </Typography>
            </Grid>
            { subscriptionData.hasSubscription && (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#052843" }}
                  >
                    Subscription Status: {subscriptionData?.status || "Inactive"} 
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#052843" }}
                  >
                    Subscription Creator: {subscriptionData?.subscriptionCreator|| "Inactive"} 
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#052843" }}
                  >
                    Subscription Start Date: {convertUnixToDate(subscriptionData?.current_period_start) || 'Not Available'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#052843" }}
                  >
                    Subscription End Date: {convertUnixToDate(subscriptionData?.current_period_end) || 'Not Available'}
                  </Typography>
                </Grid>
              </>
            )}
            { !subscriptionData.hasSubscription && (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#052843" }}
                  >
                    Subscription Status: {subscriptionData?.status || "Inactive"} 
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
        <Paper
          style={{
            padding: "20px",
            marginTop: "20px",
            border: "3px solid #576b73",
            borderRadius: "25px",
          }}
        >
          <Grid spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  gap: "7px",
                  flexWrap: "wrap",
                }}
              >
                { !subscriptionData.hasSubscription && (
                  <div>
                    <Container
                      disablegutters="true"
                      maxwidth="xl"
                      sx={{ pt: 50, pb: 6 }}
                      component="main"
                      className="pricing-main"
                      id="pricing"
                    >
                      <div className="pricing-heading">
                        <Typography
                          component="h4"
                          variant="h4"
                          align="center"
                          sx={{ color: "#26425a", fontWeight: "600" }}
                          gutterBottom
                        >
                          Try it free for 14 days
                        </Typography>
                        <button className="nav-btn" onClick={createFreeTrial}>
                          Add To Slack 
                        </button>
                      </div>
                      <div className="pricing-cards-main">
                        <DashboardPricingCard
                          price={9}
                          planName="Starter"
                          features={{
                            feature1: "Unlimited Seats",
                            feature2: "Chat GPT 3.5",
                            feature3: "50,000  tokens",
                            feature4: "37,500 words",
                          }}
                          btnText="Add to Slack"
                        />
                        <DashboardPricingCard
                          price={20}
                          planName="Business"
                          features={{
                            feature1: "Unlimited Seats",
                            feature2: "Chat GPT 4 Turbo",
                            feature3: "100,000  tokens",
                            feature4: "75,000 words",
                          }}
                          btnText="Add to Slack"
                        />
                        <DashboardPricingCard
                          price={100}
                          planName="Teams"
                          features={{
                            feature1: "Unlimited Seats",
                            feature2: "Chat GPT 4 Turbo",
                            feature3: "750,000  tokens",
                            feature4: "560,000 words",
                          }}
                          btnText="Add to Slack"
                        />
                        <div className="dont-align-center">
                          <DashboardPricingCardSales
                            planName="Custom"
                            features={{
                              feature1: "One-time purchase",
                              feature2: "Custom AI personalities",
                              feature3: "Integration with model of your choice",
                              feature4: "Option to plug in your Azure OpenAI account",
                            }}
                            btnText="Talk to Sales"
                            emailLink="info@peznetsolutions.com"
                          />
                        </div>
                      </div>
                    </Container>
                  </div> 
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  gap: "7px",
                  flexWrap: "wrap",
                }}
              >
                { subscriptionData.hasSubscription && subscriptionData.isSubscriptionCreator && (
                  <>
                    <Grid item xs={12}>
                      <Button className="btn" variant="contained" color="primary" href={'https://billing.stripe.com/p/login/dR614j1Fl7kDehG5kk' + `?prefilled_email=${encodeURIComponent(user.email)}`}> 
                      Manage Your Subscription
                      </Button>
                      <Button className="btn" variant="contained" color="primary" href={`https://slack.com/oauth/v2/authorize?&scope=channels:history,chat:write,commands,files:read,groups:history,im:history,mpim:history,users:read,users:read.email,users.profile:read&user_scope=openid&client_id=2648747263859.6047015848518&redirect_uri=${encodeURIComponent(process.env.REACT_APP_OAUTH_CALLBACK)}`}>
                          Add To Slack
                      </Button>
                    </Grid>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <div>
        <Video />
      </div>
    </ThemeProvider>
  );
};

export default Dashboard;

import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Logo from "../assets/logo.png";
import { AiOutlineClose } from "react-icons/ai";
// import { Link } from "react-router-dom";
import Link from '@mui/material/Link';
const drawerWidth = 240;
const navItems = [
  { path: "Pricing", route: "/" },
  { path: "Features", route: "/" },
];

const NavBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { loginWithRedirect } = useAuth0(); 

  const clearCache = () => {
    localStorage.clear(); // This clears everything in local storage
  };

  const handleLogin = async () => {
    clearCache();
    await loginWithRedirect();
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const closeNav = () => {
    setMobileOpen(false);
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    // if (section) {
    setMobileOpen(false);
    section.scrollIntoView({ behavior: "smooth" });
    console.log("called");
    // }
  };

  const drawer = (
    <Box
      // onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Center vertically
        height: "100%",
        position: "relative",
      }}
    >
      <IconButton
        color="inherit"
        aria-label="close drawer"
        onClick={closeNav}
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <AiOutlineClose />
      </IconButton>
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => handleScrollToSection(item.path.toLowerCase())}
            >
              <ListItemText
                // primary={item.path}
                sx={{ textTransform: "capitalize" }}
              >
                <button style={{ fontWeight: "500" }} onClick={handleLogin}>
                  {item.path}
                </button>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}

        <button 
          className="nav-btn nav-link" 
          onClick={handleLogin}
          style={{ marginTop: "4px" }}>
            Log In
        </button>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="static"
        elevation={0}
        sx={{
          backgroundColor: "#fff",
          color: "#000",
          maxWidth: "1600px",
          margin: "auto",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h2"
            component="div"
            sx={{
              flexGrow: 1,
              color: "#26425a",
              columnGap: "30px",
            }}
          >
            <Link
              to={"/"}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1px",
                justifyContent: "start",
              }}
              className="nav-logo-main"
            >
              <img src={Logo} alt="logo" className="nav-logo" />
              <h3 className="nav-logo-text">Insight Bot</h3>
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end" // Move the hamburger icon to the right
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {navItems.map((item, index) => (
              <Button
                key={index}
                onClick={() => handleScrollToSection(item.path.toLowerCase())}
                sx={{
                  color: "#000",
                  textTransform: "capitalize",
                  fontWeight: "500",
                }}
              >
                {item.path}
              </Button>
            ))}

            <button 
              className="nav-btn" 
              style={{ marginLeft: "12px" }}
              onClick={handleLogin}>
              Log In
            </button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          anchor="right"
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;

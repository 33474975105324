import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Pricing from './components/Pricing';
import Dashboard from './components/Dashboard';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Video from './components/Video';
import "./App.scss";
import "./Stylesheet/Style.css";
import "./Fonts/style.css";


function App() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const checkAuthSession = async () => {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently({ cacheMode: 'default' });
        } catch (error) {
          console.log('Error refreshing access token:', error);
          // Redirect the user to the login page
          loginWithRedirect();
        }
      }
    };

    checkAuthSession();
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div id="home">
      {isAuthenticated ? (
        <Dashboard />
      ) : (
        <div>
          <NavBar />
          <Hero />
          <Video />
          <Pricing />
        </div>
      )}
    </div>
  );
}

export default App;

